<template>
  <div class="auth-wrapper auth-v2" style="max-width: 100% !important;">
    <b-row class="auth-inner m-0" v-if="mode === 'verifyEmail'">
      <!-- Brand logo-->
      <b-link
        class="brand-logo"
        href="/"
      >
        <b-img-lazy
          fluid
          :src="imgUrl"
          alt="logo SEOcloud"
          class="img-logo"
          width="340px"
          height="40px"
        />
      </b-link>
      <!-- /Brand logo-->

      
        <b-col
          lg="12"
          class="d-flex align-items-center p-5"
        >
          <div style="margin: auto; background: #fff; height: 350px; width: 550px;">
            <p style="margin: auto; font-size: 24px; color: #181e38; text-align: justify; padding: 28% 53px;">
              Congratulations! Your email address has been successfully verified.
            </p>
          </div>
        </b-col>
    </b-row>

    <b-row class="auth-inner m-0" v-else>
      <!-- Brand logo-->
      <b-link
        class="brand-logo"
        href="/"
      >
        <b-img-lazy
          fluid
          :src="imgUrl"
          alt="logo SEOcloud"
          class="img-logo"
          width="340px"
          height="40px"
        />
      </b-link>
      <!-- /Brand logo-->
        <!-- Left Text-->
        <b-col
          lg="8"
          class="d-none d-lg-flex align-items-center p-5"
        >
          <div class="w-100 d-lg-flex align-items-center justify-content-center px-5">
            <b-img
              :src="ghaphicsImg"
              fluid
              alt="Register V2"
            />
          </div>
        </b-col>
        <!-- /Left Text-->

        <!-- Reset password-->
        <b-col
          lg="4"
          class="d-flex align-items-center auth-bg px-2 p-lg-5"
        >
          <b-col
            sm="8"
            md="6"
            lg="12"
            class="px-xl-2 mx-auto"
          >
            <b-card-title
              title-tag="h2"
              class="font-weight-bold mb-1 color-text-black"
            >
              New Password
            </b-card-title>

            <!-- form -->
            <validation-observer ref="simpleRules">
              <b-form
                class="auth-reset-password-form mt-2"
                method="POST"
                @submit.prevent="validationForm"
              >
                <!-- password -->
                <b-form-group
                  label="New Password"
                  label-for="reset-password-new"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="New Password"
                    vid="Password"
                    rules="required|min:8|password"
                    :custom-messages="{required: '{_field_} is required'}"
                  >
                    <b-input-group
                      class="input-group-merge"
                      :class="errors.length > 0 ? 'is-invalid':null"
                    >
                      <b-form-input
                        id="reset-password-new"
                        v-model="password"
                        :type="password1FieldType"
                        :state="errors.length > 0 ? false:null"
                        class="form-control-merge"
                        name="reset-password-new"
                        placeholder="············"
                      />
                      <b-input-group-append
                        is-text
                        class="cursor-pointer"
                        @click="togglePasswordVisibility"
                      >
                        <feather-icon
                          class="cursor-pointer"
                          :icon="passwordToggleIcon"
                        />
                      </b-input-group-append>
                    </b-input-group>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>

                <!-- confirm password -->
                <b-form-group
                  label-for="reset-password-confirm"
                  label="Confirm Password"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="Confirm Password"
                    rules="required|min:8|password|confirmed:Password"
                    :custom-messages="{required: '{_field_} is required', confirmed: '{_field_} do not match'}"
                  >
                    <b-input-group
                      class="input-group-merge"
                      :class="errors.length > 0 ? 'is-invalid':null"
                    >
                      <b-form-input
                        id="reset-password-confirm"
                        v-model="cPassword"
                        :type="password2FieldType"
                        class="form-control-merge"
                        :state="errors.length > 0 ? false:null"
                        name="reset-password-confirm"
                        placeholder="············"
                      />
                      <b-input-group-append
                        is-text
                        class="cursor-pointer"
                        @click="togglePassword2Visibility"
                      >
                        <feather-icon
                          class="cursor-pointer"
                          :icon="password2ToggleIcon"
                        />
                      </b-input-group-append>
                    </b-input-group>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>

                <!-- submit button -->
                <b-button
                  block
                  type="submit"
                  variant="primary"
                >
                  Reset Password
                </b-button>
              </b-form>
            </validation-observer>

            <p class="text-center mt-2">
              <b-link :to="{name:'auth-login-v2'}">
                <feather-icon icon="ChevronLeftIcon" /> Log in
              </b-link>
            </p>
          </b-col>
        </b-col>
        <!-- /Reset password-->
    </b-row>
  </div>
</template>

<script>
/* eslint-disable global-require */
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import VuexyLogo from '@core/layouts/components/Logo.vue'
import {
  BRow, BCol, BCardTitle, BCardText, BForm, BFormGroup, BInputGroup, BInputGroupAppend, BLink, BFormInput, BButton, BImg, BImgLazy,
} from 'bootstrap-vue'
import { required, min, password } from '@validations'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { firebase, db } from '@/firebase'
import store from '@/store/index'

export default {
  components: {
    VuexyLogo,
    BRow,
    BCol,
    BButton,
    BCardTitle,
    BCardText,
    BForm,
    BFormGroup,
    BImg,
    BInputGroup,
    BLink,
    BFormInput,
    BInputGroupAppend,
    BImgLazy,
    ValidationProvider,
    ValidationObserver,
  },
  data() {
    return {
      userEmail: '',
      cPassword: '',
      password: '',
      sideImg: require('@/assets/images/logo/logo-seocloud.svg'),
      ghaphicsImg: require('@/assets/images/banner/ghaphics.svg'),
      // sideImg: require('@/assets/images/pages/reset-password-v2.svg'),
      // validation
      required,
      min,
      password,

      // Toggle Password
      password1FieldType: 'password',
      password2FieldType: 'password',

      mode: '',
    }
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
    imgUrl() {
      if (store.state.appConfig.layout.skin === 'dark') {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.sideImg = require('@/assets/images/logo/logo-seocloud.svg')
        return this.sideImg
      }
      return this.sideImg
    },
    passwordToggleIcon() {
      return this.password1FieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
    password2ToggleIcon() {
      return this.password2FieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
  },
  methods: {
    togglePasswordVisibility() {
      this.password1FieldType = this.password1FieldType === 'password' ? 'text' : 'password'
    },
    togglePassword2Visibility() {
      this.password2FieldType = this.password2FieldType === 'password' ? 'text' : 'password'
    },
    validationForm() {
      this.$refs.simpleRules.validate().then(success => {
        if(success) {
          // console.log(this.$route.query.oobCode,this.password)
          firebase.auth().confirmPasswordReset(this.$route.query.oobCode, this.password)
            .then(() => {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: 'Password Changed',
                  icon: 'EditIcon',
                  variant: 'success',
                },
              })

              setTimeout(() => {
                window.location.href = '/login'
              }, 1000)
            })
            .catch(error => {
            // Some error occurred, you can inspect the code: error.code
            // Common errors could be invalid email and invalid or expired OTPs.
              // console.log(error)
              if (error.code === 'auth/invalid-action-code') {
                this.$toast({
                  component: ToastificationContent,
                  position: 'top-right',
                  props: {
                    title: 'Code error',
                    icon: 'CoffeeIcon',
                    variant: 'danger',
                    text: 'The code has already been used, has expired or is invalid, contact support.',
                  },
                })
              } else if (error.code === 'auth/weak-password') {
                this.$toast({
                  component: ToastificationContent,
                  position: 'top-right',
                  props: {
                    title: 'Password is weak',
                    icon: 'CoffeeIcon',
                    variant: 'danger',
                    text: 'The password is weak, it must have a minimum of 6 characters.',
                  },
                })
              } else if (error.code === 'auth/invalid-email') {
                this.$toast({
                  component: ToastificationContent,
                  position: 'top-right',
                  props: {
                    title: 'Invalid email',
                    icon: 'CoffeeIcon',
                    variant: 'danger',
                    text: `${error.message}`,
                  },
                })
              } else {
                this.$toast({
                  component: ToastificationContent,
                  position: 'top-right',
                  props: {
                    title: 'Error',
                    icon: 'CoffeeIcon',
                    variant: 'danger',
                    text: `${error.message}, contact support.`,
                  },
                })
              }
            })
        }
      })
    },
    verifyEmail() {
      firebase.auth().applyActionCode(this.$route.query.oobCode)
        .then(() => {
          // this.successMessage = 'Correo verificado con éxito.';
          console.log('verified');
        })
        .catch((error) => {
          // this.error = 'Error al verificar el correo: ' + error.message;
          console.log(error.message);
        });
    },
  },
  created() {
    const urlParams = new URLSearchParams(window.location.search);
    this.mode = urlParams.get('mode')
    var modeURL = urlParams.get('mode')
    if(modeURL === 'verifyEmail') {
      this.verifyEmail();
    }
  }
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';
</style>

<style>
body {
  background: url("https://firebasestorage.googleapis.com/v0/b/seocloud-7e24d.appspot.com/o/stripes-bkg.png?alt=media&token=174016c2-d520-4123-b4b7-bead206719ab") 0% 0% no-repeat padding-box,linear-gradient(115deg, #181e38 47%, #7838ff 180%) !important;
}
/* .lnkHv:hover {
  color: #f4d6ff !important;
} */
.img-logo {
  width: 340px;
  height: 40px;
  position: relative;
  top: 25px;
  left: 20px;
}
</style>
